<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card class="person-list-wrapper">
    <v-row class="mt-4 px-1 mb-1" no-gutters>
      <v-col cols="12" sm="4">
        <v-autocomplete outlined dense :loading="loadempl" :disabled="loadempl" :items="_showWorkers"
          v-model="phoneNumber" item-text="fullname" item-value="uuid" clearable @click:clear="resetFilter"
          label="Select Worker">
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title> <span>{{ data.item.fullname }}</span>
                </v-list-item-title>
                <v-list-item-subtitle class="d-flex">
                  <div>{{ data.item.rol }} <div>{{ data.item.phone ? data.item.phone : 'No Phone' }}</div>
                  </div> <v-spacer></v-spacer>
                  <template v-if="data.item.unreadsms != 0">
                    <v-badge class="ma-4" color="green" :content="data.item.unreadsms">
                    </v-badge>
                  </template>

                </v-list-item-subtitle>

              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>


      <v-col cols="12" sm="4" class="mx-2">
        <v-autocomplete outlined dense :loading="loadempl" :disabled="loadempl" :items="contacts"
          v-model="selectedPerson" item-text="name" item-value="number" clearable label="Select Contact">
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.origin"></v-list-item-subtitle>
                <v-list-item-subtitle v-html="data.item.number ? data.item.number : 'not phone'"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="2"><v-btn :color="unrelated ? 'error' : 'info'" @click="setUnrealtedContact(unrelated)">{{
        unrelated ? 'Clear' : 'Unrelated' }}</v-btn></v-col>
    </v-row>

    <template v-if="loading">
      <div class="d-flex justify-center my-4">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </template>
    {{/* List of contacts */ }}
    <template v-if="unrelated">
      <template v-if="contactsUnrelated.length == 0">
        <p class="ma-2 red--text">There is no messages</p>
      </template>
      <v-list class="person-list-unrrelated" >
        <v-list-item-group v-model="selectedPerson" color="grey">
          <v-divider v-if="contactSmsList.length > 0" inset />

          <template v-for="(contact, index) in contactsUnrelated">
            <v-list-item :class="contact.number == _numbercontact ? 'selected' : ''" :disabled="loading"
              :key="contact.number" link @click="setContact(contact)">
              <v-list-item-content>
                <v-list-item-title :class="contact.unread_sms != 0
                  ? 'font-weight-bold d-flex pr-1'
                  : 'd-flex pr-1'
                  ">
                  <!--  <v-icon>{{ contactIcon(contact) }}</v-icon> -->
                  {{ contact.name }}
                  <v-spacer></v-spacer>
                  <template>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="success" v-on="on" icon :disabled="dialogmakeCall"
                          @click="makeCallV(contact.number)">
                          <v-icon>mdi-phone-forward</v-icon>
                        </v-btn>
                      </template>
                      <span>Make Call</span>
                    </v-tooltip>
                  </template>

                </v-list-item-title>
                <v-list-item-subtitle class="d-flex">
                  <div>
                    <div>
                      {{ contact.origin }}
                    </div>
                    <div>
                      {{ contact.number }}
                    </div>
                  </div>

                  <v-spacer></v-spacer>
                  <template v-if="contact.unread_sms != 0">
                    <div class="ml-auto">
                      <div class="container-badge">
                        <v-badge class="mt-4" color="green" :content="contact.unread_sms">

                        </v-badge>
                      </div>

                      <span class="messages font-weight-bold  mx-1">messages</span>
                    </div>

                  </template>

                </v-list-item-subtitle>

              </v-list-item-content>

            </v-list-item>
            <v-divider v-if="contact.length - 1 != index" :key="'d-' + index" inset />
          </template>


        </v-list-item-group>
      </v-list>
      <template v-if="moreContact">

        <template v-if="loadingMore">
          <div class="text-center blue--text my-2">Loading...</div>
        </template>
        <template v-else>
          <div class="text-center cursor-pointer blue--text my-2" @click="getMoreContacts">More contacts...</div>
        </template>
      </template>
    </template>
    <template v-else>
      <template v-if="contacts.length == 0">
        <p class="ma-2 red--text">{{!phoneNumber?'Select any employee ': loading ? 'Loading...wait a few seconds!!':'There is no messages'}}</p>
      </template>

      <v-list class="person-list " >
        <v-list-item-group v-model="selectedPerson" color="grey">
          <v-divider v-if="contactSmsList.length > 0" inset />

          <template v-for="(contact, index) in contacts">
            <v-list-item :class="contact.number == _numbercontact ? 'selected' : ''" :disabled="loading"
              :key="contact.number" link @click="setContact(contact)">
              <v-list-item-content>
                <v-list-item-title :class="contact.unread_sms != 0
                  ? 'font-weight-bold d-flex pr-1'
                  : 'd-flex pr-1'
                  ">
                  <!--  <v-icon>{{ contactIcon(contact) }}</v-icon> -->
                  {{ contact.name }}
                  <v-spacer></v-spacer>
                  <template>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn color="success" v-on="on" icon :disabled="dialogmakeCall"
                          @click="makeCallV(contact.number)">
                          <v-icon>mdi-phone-forward</v-icon>
                        </v-btn>
                      </template>
                      <span>Make Call</span>
                    </v-tooltip>
                  </template>

                </v-list-item-title>
                <v-list-item-subtitle class="d-flex">
                  <div>
                    <div>
                      {{ contact.origin }}
                    </div>
                    <div>
                      {{ contact.number }}
                    </div>
                  </div>

                  <v-spacer></v-spacer>
                  <template v-if="contact.unread_sms != 0">
                    <div class="ml-auto">
                      <div class="container-badge">
                        <v-badge class="mt-4" color="green" :content="contact.unread_sms">

                        </v-badge>
                      </div>

                      <span class="messages font-weight-bold  mx-1">messages</span>
                    </div>

                  </template>

                </v-list-item-subtitle>

              </v-list-item-content>

            </v-list-item>
            <v-divider v-if="contact.length - 1 != index" :key="'d-' + index" inset />
          </template>
        </v-list-item-group>
      </v-list>
    </template>

  </v-card>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact, SMS } from "@/models/Sms";
import Vue from "vue";

import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import formatPhone from "@/utils/formatPhone";
import { getAPI } from "@/api/axios-base";
import { uniqBy } from "lodash";

export default Vue.extend({
  name: "chat-person-list",
  components: { BtnMakeCall },
  data() {
    return {
      selectedPerson: null,
      unrelated:false,
      showOnline: false,
      interval: null,
      loading: false,
      chatHeight: 0,
      phoneNumber: "",
      phoneText: "",
      loadempl: false,
      query: "",
      page:0,
      limit:20,
      moreContact:false,
      loadingMore:false,
      contactsUnrelated:[]
    };
  },
  computed: {
    ...mapState("crmCallCenterModule", ["dialogmakeCall"]),
    ...mapGetters("crmSMSModule", ["messagesPerPatient"]),
    ...mapState(["profile"]),
    ...mapGetters(["isAdmin", "isSuper", "getPhone"]),
    ...mapState("crmSMSModule", [
      "messages",
      "contacts",
      "unreads",
      "contactSelect",
      "numberSelect",
    ]),
    ...mapState("crmEmployeeModule", ["workersforSMs"]),
    _showWorkers() {
      if (this.workersforSMs.length == 0) {
        return [];
      }
      const filt = this.workersforSMs.filter(
        (w) => w.phone != null && w.phone != undefined
      );
      return filt;
    },

    _numbercontact() {
      if (this.contactSelect == null) {
        return "";
      }
      return this.contactSelect.number;
    },

    contactSmsList() {
      return Array.from(this.messagesPerPatient.keys());
    },

    isAdministrator() {
      return this.isAdmin || this.isSuper;
    },
  },
  async mounted() {
   // this._getNumberContacts();
    this.loadempl = true;

    await this.getWorkers();
    this.loadempl = false;
    if (localStorage.getItem("uuidemployee")) {
      this.phoneNumber = localStorage.getItem("uuidemployee");
    }
  },
  destroyed() {
    this.mutnumberSelect(null);
    this.mutSetContact(null);
    clearInterval(this.interval);
    localStorage.removeItem("uuidemployee");
  },

  watch: {
    selectedPerson(val) {
      if (val != undefined && val != null) {
       
        const contac = this.contacts.filter((c) => c.number == val);
        if (contac.length > 0) {
          this.setContact(contac[0]);
          
        }
      }
    },
    /* contactSelect: {
      handler() {
        (this).getsmsFiltered();
      },
    }, */

    numberSelect(val) {
      if (val == null) {
        this.phoneNumber = "";
        this.phoneText = "";
      } else {
        clearInterval(this.interval);
      }
    },

    phoneNumber(val) {
      if (val != null && val != undefined && val != "") {        
        this.setUnrealtedContact(true)
        this._getNumberContacts(val);
        this.mutnumberSelect(val);
        this.mutSetContact(null);
      } else {
        if (this.phoneText == "" || this.phoneText == null) {
          this._getNumberContacts();
          this.mutnumberSelect(null);
          this.mutSetContact(null);
        }
      }
    },
    phoneText(val) {
      if (val != null && val != undefined && val != "" && val.length == 10) {
        if (val[0] != "+") {
          val = "+1" + val;
        }

        this._getNumberContacts(val);
        this.mutnumberSelect(val);
      } else if (val != null && val.length > 0 && val.length < 10) {
        //nada
      } else {
        if (this.phoneNumber == "" || this.phoneNumber == null) {
          this._getNumberContacts();
          this.mutnumberSelect(null);
          this.mutSetContact(null);
        }
      }
    },
  },

  methods: {
    ...mapActions("crmSMSModule", ["actContac"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployeesforSms"]),
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutnumberSelect",
      "mutcontacts",
      "mutSetMessagesFilters",
    ]),
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    openCall(phone) {
      localStorage.setItem("number", phone);
      this.mutDialogmakeCall(true);
    },

    setUnrealtedContact(value){
      this.unrelated = !value;      
      if (this.unrelated) {
      this.selectedPerson=null;
      this.phoneNumber=null;
      this.mutnumberSelect(null);
      this.mutSetContact(null);
        this.getUnrelatedContact(0)
      }
      this.page=0;
           

    },
    getSkip(page){
      return page*this.limit
    },
    async getUnrelatedContact(page){
   const response = (await getAPI(`/twiliocloud/getUnrelatedSmsNumbers`,{
    params:{
      limit:this.limit,
       offset:this.getSkip(page)
    }
   })).data;
   const responseData = response.result
   this.moreContact=response.count> this.limit
    const arrayFix = [];
    responseData.forEach((element) => {
      const ele = element;
      let num = ele.s_from;
      if (num.includes("+1")) {
        num = num.replace("+1", "");
      }
      num = num.replaceAll("\t", "");
      num = num.replaceAll(" ", "");
      num = formatPhone(num);
      num = "+1" + num;
      ele.s_from = num;
      arrayFix.push(ele);
    });

    const removedDoubles = uniqBy(arrayFix, "s_from");
    
    const contactsFix = [];
    removedDoubles.forEach((rd) => {
      const phone = rd.s_from;
      contactsFix.push({
        number: phone,
        fromUnrelated: true,
        unread_sms: rd.unread_sms,
      });
    });
   
    this.contactsUnrelated=contactsFix
    },
    async getMoreContacts(){
      this.page++;
      this.loadingMore=true;
      const response = (await getAPI(`/twiliocloud/getUnrelatedSmsNumbers`,{
      params:{
      limit:this.limit,
      offset:this.getSkip(this.page)
      }
      })).data;
      this.loadingMore=false
      const responseData = response.result
      this.moreContact=response.count> this.getSkip(this.page)
      const arrayFix = [];
      responseData.forEach((element) => {
      const ele = element;
      let num = ele.s_from;
      if (num.includes("+1")) {
      num = num.replace("+1", "");
      }
      num = num.replaceAll("\t", "");
      num = num.replaceAll(" ", "");
      num = formatPhone(num);
      num = "+1" + num;
      ele.s_from = num;
      arrayFix.push(ele);
      });
      
      const removedDoubles = uniqBy(arrayFix, "s_from");
      
      const contactsFix = [];
      removedDoubles.forEach((rd) => {
      const phone = rd.s_from;
      contactsFix.push({
      number: phone,
      fromUnrelated: true,
      unread_sms: rd.unread_sms,
      });
      });
      this.contactsUnrelated= this.contactsUnrelated.concat(contactsFix) 
      
    },
    makeCallV(item) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: item,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },
    resetFilter() {
      this.phoneNumber = "";
    },

    setHeight() {
      this.chatHeight = this.$vuetify.breakpoint.height;
    },

    async _getNumberContacts(number) {
      let phone = number
        ? number
        : this.profile != null && this.profile != undefined
        ? this.profile.uuid
        : null;
      if (number) {
        this.loading=true;
        await this.actContac(phone);
        this.loading=false;
      } else {
        this.mutcontacts([]);
      }
    },
    noReadSms(contact) {
      const noread = this.unreads.filter((ms) => ms.from == contact.number);
      if (noread.length != 0) {
        return noread[0].cant;
      }
      return 0;
    },
    async getWorkers() {
      await this.actGetEmployeesforSms();
    },

    setContact(account) {
      this.mutSetContact(account);
      this.$emit("contact", account);
    },
  },
});
</script>

<style lang="scss" scope>
.messages {
  font-size: 12px;
}

.selected {
  background-color: rgba(206, 203, 203, 0.514);
}


.container-badge {
  margin-left: 35px !important;
}
</style>

<i18n>
{
  "en": {
    "placeholder": "Search contacts"
  },
  "es": {
    "placeholder": "Buscar contactos"
  }
}
</i18n>
